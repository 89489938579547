(function($, window) {
  DelhiZoo.indexPage = {
    init: function() {
      DelhiZoo.indexPage.initializeMasterSlider();
    },
    vars: {},
    initializeMasterSlider: function() {
      var slider = new MasterSlider();
      // adds Arrows navigation control to the slider.
      slider.control("arrows");
      slider.control("bullets");

      slider.setup("masterslider", {
        width: 1600, // slider standard width
        height: 700, // slider standard height
        space: 0,
        speed: 45,
        layout: "fullwidth",
        loop: true,
        preload: 0,
        autoplay: true,
        view: "parallaxMask"
      });
    }
  };

  window.DelhiZoo = DelhiZoo;
  $(document).ready(DelhiZoo.indexPage.init);
})(jQuery, this);
